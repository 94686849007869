import axios from "axios";

const API_URL = "https://areco.gob.ar:9533/api/auth/" //"https://areco.gob.ar:9532/auth/"//

class AuthService {
    async login(email, password) {
        console.log(`email : ${email} pass : ${password}`)
        const response = await axios
            .post(API_URL + "login", {
                email,
                password
            });
        if (response.data.accessToken) {
            //console.log(response.data.accessToken);
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(name, email, password, cuim) {
        return axios.post(API_URL + "signup", {
            name,
            email,
            password,
            cuim
        });
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
    }
}

export default new AuthService();