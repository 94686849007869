import React from "react";
import { Table } from "react-bootstrap";
import comercioService from "../service/comercio-service";

const AsociadosCuitCuim = ({ asociados, comercioxcuim }) => {
    const vercomercios = (cuim) => {
        comercioService.getcomercios(cuim)
            .then((response) => {
                console.log(response.data.data)
                comercioxcuim(response.data.data)
            })
    }
    return (
        <div className="asociado_container">
            <h3>Asociados a tu usuario</h3>
            <h6>Sistema de Facturación Electrónica - Municipalidad de San Antonio de Areco</h6>
            <Table striped bordered hover  >

                <thead>
                    <tr>
                        <th>Cuim</th>
                        <th>Descripcíon</th>
                        <th>Accion</th>
                    </tr>

                </thead>
                <tbody>
                    {asociados.map((data, i) => {
                        return (
                            <tr key={i}>
                                <td>{data.cuim}</td>
                                <td>{data.razonSocial}</td>
                                <td>
                                    <div className="button-container">
                                        <button  onClick={() => vercomercios(data.cuim)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}
export default AsociadosCuitCuim