import http from "./http-common";
import authHeader from './auth-header';
import axios from "axios";



const user = JSON.parse(localStorage.getItem('user'));
const getasociados = (cuit) => {
    return http.get("/api/cuit_cuim/buscarporcuit?cuit=" + cuit, { headers: authHeader() })
}
const getcomercios=(cuim)=>{
    return http.get("/api/comercio/comercioporcuim?cuim="+cuim,{ headers: authHeader() })
}
const getddjj=(nro_comercio)=>{
    return http.get("/api/ddjj/ddjjporcomercioactivo?nro_comercio="+nro_comercio,{ headers: authHeader() })
}


const DDJJpdf=async (nro_comercio)=>{
    try {
      const response = await http.get(`api/comercio/comprobanteddjj/${nro_comercio}`, {
        headers: authHeader(),
        responseType: 'blob'
      });
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    } catch (error) {
     // console.log(error);
    }
  }
const comercioService={
    getasociados,
    getcomercios,
    getddjj,
    DDJJpdf
}

export default comercioService