import React from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import dibujo from "../assest/dibujo.png"
import "./Entrada.css";
const Home = () => {
    let navigate = useNavigate();
    return (
        <div className="text-center  titulo_entrada">
            <h1>Bienvenido</h1>
            <Row>
               
                <Col className="botones">
                    <div className="botones_bienvenida">
                        <div className="button-container">
                            <button class="btnn" onClick={() => navigate("/login")}>
                                Ingresar
                            </button>
                        </div>
                        <div className="button-container">
                            <button class="btnn" onClick={() => navigate("/registro")}>
                                Registrarte
                            </button>
                        </div>
                    </div>

                </Col>

            </Row>
        </div>

    )
}
export default Home