import React from "react";
import { Button, Row, Col } from 'react-bootstrap';
import BoletaUnica from "../assest/logo_factura.svg";
import IIBB from "../assest/logo_iibb.svg";
import { useNavigate } from 'react-router-dom';
import "./Entrada.css";
const Entrada = (props) => {
    let navigate = useNavigate();
    props.entrada(false);
    return (

        <div className="text-center  titulo_entrada ">
            <h1>Factura Electrónica</h1>
            <Row className="button_row">
                <Col className="button_col" lg={5} xl={5} xxl={5} md={5}>
                    <div className="button-container">
                        <button class="btnn" onClick={() => { navigate("/home"); props.entrada(true) }}>
                            Consulta de tasa
                        </button>
                    </div>
                </Col>
                <Col className="button_col" lg={5} xl={5} xxl={5} md={5}>
                    <div className="button-container">
                        <button class="btnn" onClick={() => { navigate("/home"); props.entrada(true) }}>
                            Ingresos Brutos
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default Entrada