import React, { useEffect, useState } from "react";
import boletaService from "../service/boletas-service";
import { Table, Button } from "react-bootstrap";
import AuthService from "../service/auth.service";
import { TailSpin } from 'react-loader-spinner'

const BoletaUnica = () => {
    const [boleta, setBoleta] = useState(null);
    const [seguridadHigiene,SetSeguridadHigiene]=useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingPagar, setLoadingPagar] = useState(false);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        setSpinner(true);
        const user = AuthService.getCurrentUser()

        if (user) {
            boletaService.getBoleta(user.cuim)
                .then((response) => {
                    console.log(response.data.data);
                    setBoleta(response.data.data);
                    setSpinner(false);

                })
            boletaService.getSeguridadHigiene(user.cuim)
            .then((response) => {
                console.log(response.data.data);
                SetSeguridadHigiene(response.data.data);
                setSpinner(false);

            })
        }
    }, [])

    const onhandleClick = () => {
        setLoading(true)
        boletaService.boletapdf(boleta.cuim, boleta.nroComprob)
            .then((response) => setLoading(false))

    }
    const ClickSegHig = () => {
        setLoading(true)
        boletaService.SegHigpdf(seguridadHigiene.cuim, seguridadHigiene.nroComprob)
        .then((response) => setLoading(false))
            

    }

    const sumartransaccion = (e) => {
        console.log(boleta.cod50cuota + "/" + boleta.transaccion)
        const data = [boleta]

        data[0].transaccion = Number(data[0].transaccion) + 1
        setBoleta(data[0])
        console.log(data[0])
        boletaService.contadorTrasacciones(data[0])
    }
    const sumartransaccionSeg = (e) => {
        console.log(seguridadHigiene.cod50cuota + "/" + seguridadHigiene.transaccion)
        const data = [seguridadHigiene]

        data[0].transaccion = Number(data[0].transaccion) + 1
        SetSeguridadHigiene(data[0])
        console.log(data[0])
        boletaService.contadorTrasaccionesSeg(data[0])
    }

    return (
        <div className="text-center mt-4">
            <h1>Boleta Unica</h1>
            {spinner ?
                (
                    <div className="spinner">
                        <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                ) :
                (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nro. Comprobante</th>
                                <th>Descripcion</th>
                                <th>Cuota</th>
                                <th>Nombre y Apellido</th>
                                <th>Consultar</th>
                                <th>Pagar</th>
                            </tr>
                        </thead>
                        {boleta === null ? (null) :
                            (<tbody>
                                <tr>
                                    <td>{boleta.nroComprob}</td>
                                    <td>{boleta.descripcion}</td>
                                    <td>{boleta.cuota}/{boleta.anio}</td>
                                    <td>{boleta.apynom}</td>
                                    <td><Button
                                        variant="outline-success"
                                        onClick={onhandleClick}
                                        disabled={loading}
                                    >{loading ? ("Buscando") : ("Consultar")}
                                    </Button>{' '}
                                    </td>
                                    <td >
                                        <form action='https://boletas.areco.gob.ar:8008/anonimo/components/prueba1.php' method='post' target='_blank' >
                                            <input type='hidden' name='nro_comprob' value={boleta.nroComprob} />
                                            <input type='hidden' name='monto_total' value={boleta.montoTotal} />
                                            <input type='hidden' name='cuota' value={boleta.cuota} />
                                            <input type='hidden' name='anio' value={boleta.anio} />
                                            <input type='hidden' name='nro_transaccion' value={boleta.cod50cuota + "/" + boleta.transaccion} />
                                            <input type='hidden' name='descripcion' value={boleta.descripcion} />

                                            <Button
                                                type='submit'
                                                variant="outline-success"
                                                onClick={sumartransaccion} >
                                                Pagar
                                            </Button>
                                        </form>
                                    </td>

                                </tr>
                            </tbody>)}
                            {seguridadHigiene === null ? (null) :
                            (<tbody>
                                <tr>
                                    <td>{seguridadHigiene.nroComprob}</td>
                                    <td>{seguridadHigiene.descripcion}</td>
                                    <td>{seguridadHigiene.cuota}/{seguridadHigiene.anio}</td>
                                    <td>{seguridadHigiene.apynom}</td>
                                    <td><Button
                                        variant="outline-success"
                                        onClick={ClickSegHig}
                                        disabled={loading}
                                    >{loading ? ("Buscando") : ("Consultar")}
                                    </Button>{' '}
                                    </td>
                                    <td >
                                        <form action='https://boletas.areco.gob.ar:8008/anonimo/components/prueba1.php' method='post' target='_blank' >
                                            <input type='hidden' name='nro_comprob' value={seguridadHigiene.nroComprob} />
                                            <input type='hidden' name='monto_total' value={seguridadHigiene.montoTotal} />
                                            <input type='hidden' name='cuota' value={seguridadHigiene.cuota} />
                                            <input type='hidden' name='anio' value={seguridadHigiene.anio} />
                                            <input type='hidden' name='nro_transaccion' value={seguridadHigiene.cod50cuota + "/" + seguridadHigiene.transaccion} />
                                            <input type='hidden' name='descripcion' value={seguridadHigiene.descripcion} />

                                            <Button
                                                type='submit'
                                                variant="outline-success"
                                                onClick={sumartransaccionSeg} >
                                                Pagar
                                            </Button>
                                        </form>
                                    </td>

                                </tr>
                            </tbody>)}
                    </Table>
                )}

        </div>
    )
}
export default BoletaUnica