import http from "./http-common";
import authHeader from './auth-header';
import axios from "axios";

const getBoleta = (cuim) => {
    return http.get("/api/cuim/" + cuim, { headers: authHeader() })
}
const getSeguridadHigiene = (cuim) => {
    return http.get("/api/cuim/comercio/" + cuim, { headers: authHeader() })
}
const getBoletaInmueble = (inmueble) => {
    return http.get("api/byinmueble/" + inmueble, { headers: authHeader() })
}

const getBoletaComercio = (comercio) => {
    return http.get("api/bycomercio/" + comercio, { headers: authHeader() })
}

const getBoletaDominio = (dominio) => {
    return http.get("api/bydominio/" + dominio, { headers: authHeader() })
}
const getUserAll =()=>{
    return http.get("/api/usuario/userAll",{ headers: authHeader() })
}

const getRolesAll=()=>{
    return http.get("/api/roles/rolesAll",{ headers: authHeader() })
}
const boletapdf = (cuim, nro_comprob) => {
    console.log(`Cuim ${cuim} \n numero de comprobante ${nro_comprob}`)
    var data = new FormData();
    data.append('cuim', cuim);
    data.append('nro_comprob', nro_comprob);

    var config = {
        method: 'post',
        url: 'https://boletas.areco.gob.ar:8008/facturas/unica.php',
        headers: {
            "Content-Type": "multipart/form-data",
        },
        responseType: 'blob',
        data: data
    };

    return axios(config)
        .then(function(response) {

            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(function(error) {
            console.log(error);
        });
}

const SegHigpdf = (cuim, nro_comprob) => {
    console.log(`Cuim ${cuim} \n numero de comprobante ${nro_comprob}`)
    var data = new FormData();
    data.append('cuim', cuim);
    data.append('nro_comprob', nro_comprob);

    var config = {
        method: 'post',
        url: 'https://boletas.areco.gob.ar:8008/facturas/comercio.php',
        headers: {
            "Content-Type": "multipart/form-data",
        },
        responseType: 'blob',
        data: data
    };

    return axios(config)
        .then(function(response) {

            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(function(error) {
            console.log(error);
        });
}

const enviarConfirmacion = (mail, nombre) => {
    return http.get("/api/auth/mail?email=" + mail + "&nombre=" + nombre)
}

const nuevopassword = (email, password) => {
    const data = {
        email,
        password
    }
    return http.post("/api/auth/nuevopassword", data)
}
const contadorTrasacciones=(data)=>{
    return http.post("/api/cuim/transaccion",data,{ headers: authHeader() })
}

const contadorTrasaccionesSeg=(data)=>{
    return http.post("/api/cuim/transaccion/comercio",data,{ headers: authHeader() })
}
const enviarConfirmacionPass = (mail) => {
    return http.get("/api/auth/mailpassword?email=" + mail)
}
const modificarDdjj=(data)=>{
    return http.put("/api/ddjj/modificar",data,{ headers: authHeader() })
}
const modificarRole=(data)=>{
    return http.post("/api/usuario/modificaRol",data,{ headers: authHeader() })
}
const boletaService = {
    getBoleta,
    boletapdf,
    enviarConfirmacion,
    nuevopassword,
    enviarConfirmacionPass,
    getBoletaInmueble,
    getBoletaComercio,
    getBoletaDominio,
    modificarDdjj,
    getUserAll,
    getRolesAll,
    modificarRole,
    contadorTrasacciones,
    getSeguridadHigiene,
    SegHigpdf,
    contadorTrasaccionesSeg
   
}

export default boletaService