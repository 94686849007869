
import React, { useEffect, useState, useRef } from "react";
import boletaService from "../service/boletas-service";
import moment, { locale } from 'moment'
import comercioService from "../service/comercio-service";
import "./DDjjporcomercio.css"
import Swal from "sweetalert2";
//import CurrencyInput from 'react-currency-input-field';
import CurrencyInput from 'react-currency-masked-input'

const { format: formatCurrency } = Intl.NumberFormat('es-AR', {
    currency: 'PES',
    style: 'currency',
});

function useATMInput() {
    const [value, setValue] = useState('');
    function handleChange(e) {

        const decimal = Number(e.target.value.replace(/\D/g, '')) / 100;
        setValue(formatCurrency(decimal || 0).replace('PES\xa0', ''));
    }
    return [value, handleChange];
}


const DDjjporcomercio = ({ ddjj }) => {

    const [data, setData] = useState(ddjj);
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useATMInput();
    const [esVisible, setesVisible] = useState(false)
    const inputRef = useRef();
    let fecha = moment(new Date()).format('YYYY-MM-DD')


    const handleTextarea = (e) => {
        console.log(e.target.value);
        setData({ ...data, observaciones: e.target.value })
    }


    const onSubmit = () => {
        const vauepuntosxcomas = value.replace(/[^0-9,]+/g, "");
        const valoenumerico = vauepuntosxcomas.replace(/[,]+/g, ".");
        let rectificativa = ddjj.rectificativa + 1;
        setLoading(true)
        ddjj.observaciones = data.observaciones;
        ddjj.importe = valoenumerico;
        ddjj.fecha = fecha;
        ddjj.rectificativa = rectificativa
        boletaService.modificarDdjj(ddjj)
            .then((res) => {
                setLoading(false)
                Swal.fire(
                    "Correcto!",
                    "Se a modificado con exito su declaracion jurada",
                    "success")
                    .then((result => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    }))

            })
            .catch((error) => {
                Swal.fire(
                    "Incorrecto!",
                    "No se a modificado  su declaracion jurada",
                    "error")
            })
    }
    console.log(ddjj)

    return (
        <div className="card_ddjj">
            <div className="card_ddjj__header">
                <h2>Completa tu declaracion jurada</h2>
            </div>
            <div className="card_ddjj__body">
                <h3><span>Razon Social: </span><span>{ddjj?.comercio.cuimCuit?.razonSocial}</span></h3>
                <h3><span>Cuit: </span><span>{ddjj?.comercio.cuimCuit.cuim}</span></h3>
                <h3><span>Nro. Comercio: </span><span>{ddjj?.comercio.nroComercio}</span></h3>
                <h3><span>Rubro: </span><span>{ddjj?.comercio.rubro}</span></h3>
                <h3><span>Mes Anterior: </span><span>{ddjj?.mesAnt}</span></h3>
                <h3><span>Mes Actual: </span><span>{ddjj?.mes}</span></h3>
                <h3><span>Fecha: </span><span>{ddjj?.rectificativa === -1 ? moment(fecha).format("DD-MM-YYYY") : moment(ddjj?.fecha).format("DD-MM-YYYY")}</span></h3>
                {ddjj?.rectificativa === -1 ? <h3><span>Original </span><span></span></h3> : <h3><span>Rectificativa </span><span></span></h3>}
                <h3><span>declarado: </span><span>$  {ddjj?.importe}</span></h3>
            </div>
            <div className="card_ddjj__footer">
                <div className="button-container">
                    <button class="btnn" onClick={() => setesVisible(!esVisible)}>
                        Declarar o Rectificar
                    </button>
                </div>
                {ddjj?.rectificativa === -1 ? null:<div className="button-container">
                   
                        <button class="btnn" onClick={()=>comercioService.DDJJpdf(ddjj?.comercio.nroComercio)}>
                            Pdf
                        </button>
                  
                </div>}
            </div>
            {esVisible && <div>
                <div>
                    <h3>
                        <span>Importe: </span>
                        <span>

                            <input
                                name="importe"
                                type="text"
                                value={value}
                                onChange={setValue} placeholder="0,00"

                            />
                        </span>

                    </h3>
                    <h3>
                        <span>Observaciones: </span>
                        <span>
                            <textarea
                                name="observaciones"
                                id="observaciones"
                                value={data?.observaciones}
                                onChange={(e) => handleTextarea(e)}

                            />
                        </span>
                    </h3>
                </div>
                <div className="card_ddjj__footer">
                    <div className="button-container">
                        <button class="btnn" onClick={onSubmit}>
                            {loading ? "Enviando..." : "Enviar"}
                        </button>
                    </div>

                </div>
            </div>}
        </div>
    )
}
export default DDjjporcomercio