import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import logo from "../assest/login.png";
import FormularioLogin from "../component/Formulario_Pass";

import boletaService from "../service/boletas-service";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const NuevoPassword = () => {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const onChangeValores = (e) => {
        setLoading(true);
        console.log(`Valores ${e.UserName} pass ${e.Password} cuim ${e.Cuim}`)
        boletaService.nuevopassword(e.UserName, e.Password)
            .then((response) => {
                console.log(response)
                boletaService.enviarConfirmacionPass(response.data.email)
                    .then(() => {
                        navigate("/afterregister")
                        window.location.reload();
                        setLoading(false);
                    })
                })
            .catch((error) => {
                setLoading(false);
                        //console.log(error);
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        //console.log(resMessage);
                        Swal.fire({
                            text: resMessage,
                            icon: "error",

                        })
                    })
                }



    return (
            <Container className="App">
                <div className="text-center mt-5 titulo">
                    <div className="container-login">
                        <Card >
                            <div className="mt-3">
                                <img src={logo} alt="perfil" width="100px" />
                            </div>

                            <Card.Body>
                                <Card.Title><h2>Cambio de clave</h2></Card.Title>
                                <FormularioLogin valores={onChangeValores} loading={loading}/>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        )
    }
export default NuevoPassword;