import React, { useEffect, useState,useRef } from "react";
import comercioService from "../service/comercio-service";
import authService from "../service/auth.service";
import { Modal, ModalBody, Table } from "react-bootstrap";
import AsociadosCuitCuim from "../component/AsociadosCuitCuim";
import ComercioXCuit from "../component/ComercioxCuit";
import DDjjporcomercio from "../component/DDjjporcomercio";
import Swal from 'sweetalert2'
import { TailSpin } from 'react-loader-spinner'
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const BoletaComercio =()=>{
    const [asociados ,setasociados]=useState([]);
    const [comerciosxcuit,setcomerciosxcuit]=useState([]);
    const [ddjjss,setddjjss]=useState([]);
    const [showComercioxCuit, setshowComercioxCuit]=useState(false);
    const [showDDJJ,setshowDDJJ]=useState(false);
    const [spinner, setSpinner] = useState(false);

    const myRef = useRef(null)
    //const executeScroll = () => scrollToRef(myRef)
    
    useEffect(()=>{
    const user = authService.getCurrentUser();
    if(user){
        setSpinner(true);
        comercioService.getasociados(user.cuim)
        .then((response)=>{
            setasociados(response.data.data)
            setSpinner(false);
        }
        )}
    
    },[])

    const vercomercios=(e)=>{
        setcomerciosxcuit(e);
        setshowComercioxCuit(true);
        //executeScroll()
        scrollToRef(myRef)
       
    }

    const ddjj=(e)=>{
        comercioService.getddjj(e)
        .then((response)=>{
            if(response.data.data === null ){
                Swal.fire(
                        "Atencion!",
                        "Periodo cerrado ",
                        "error")
                setshowDDJJ(false);
            }else{
                setddjjss(response.data.data);
                setshowDDJJ(true);
            }
           
        })
    }

    

return(
    <div className="comercio_container">
    <h2>Boleta comercio</h2>
    {spinner? (
                    <div className="spinner">
                        <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                ):
                (   <div >
                        <AsociadosCuitCuim asociados={asociados} comercioxcuim={(e)=>vercomercios(e)}/>
                    </div>
                    
                )}
    
    <div ref={myRef}>
        {showComercioxCuit && <ComercioXCuit comerciosxcuit={comerciosxcuit} nro_comercio={(e)=>ddjj(e)}/>}
    </div>
    
    {showDDJJ && <DDjjporcomercio ddjj={ddjjss}/>}
    
    </div>
    
)
}
export default BoletaComercio