import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Page/Login"
import { Container, Navbar, Nav,NavDropdown  } from "react-bootstrap";
import AuthService from "./service/auth.service";
import logo from "./assest/logofull.png";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Page/Home";
import BoletaUnica from "./Page/BoletaUnica";
import Registro from "./Page/Registro";
import AfterRegister from "./Page/AfterRegister";
import Entrada from "./Page/Entrada";
import NuevoPassword from "./Page/NuevoPassword";
import Administracion from "./Page/Administracion";
import BoletaUnicaAdmin from "./Page/BoletaUnicaAdmin";
import BoletaComercio from "./Page/BoletaComercio";
import Moderator from "./Page/Moderador";
import ActvarDesactivar from "./component/Activar-Desactivar";



function App() {

    const [currentUser, setCurrentUser] = useState(undefined);
    const [entrada, setEntrada] = useState(true);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [showContadorBoard, setShowContadorBoard] = useState(false);
    const [showModeratorBoard, setShowModeratorBoard] = useState(false);

    const [boletas, setBoletas] = useState();
    const navigate = useNavigate();
    //console.log(entrada)
    const onChangeEntrada = (e) => {
        if (!e) {
            //console.log(e)
            setEntrada(e)
        } else setEntrada(true)

    }
    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            setShowAdminBoard(user.roles?.includes("ROLE_ADMIN"));
            setShowContadorBoard(user.roles?.includes("ROLE_CONTADOR"));
            setShowModeratorBoard(user.roles?.includes("ROLE_MODERATOR"));
        }
    }, []);

    const logout = () => {
        AuthService.logout();
        navigate("/");
        window.location.reload();
    }

    const dataAdmin = (e) => {
        console.log("data", e)
        setBoletas(e)
    }
    return (

        <Container fluid>
            <Navbar bg="light" expand="lg">

                <Navbar.Brand href="/entrada">
                    <img
                        href="/"
                        alt="logo"
                        src={logo}
                        width="100px"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {showAdminBoard  && <Nav.Link href="/tesoreria">Administracion</Nav.Link>}
                        {showContadorBoard &&  <Nav.Link href="/contador">Comercio</Nav.Link>}
                    </Nav >
                    
                    {showModeratorBoard &&
                        <Nav className="me-auto">

                            <NavDropdown title="Panel" id="nav-dropdown">
                                <NavDropdown.Item eventKey="4.1" href="/activar">Activar/Desactivar</NavDropdown.Item>
                                <NavDropdown.Item eventKey="4.2" href="/blanqueo">Blanqueo de clave </NavDropdown.Item>
                                <NavDropdown.Item eventKey="4.3" href="/cuim">Cuim</NavDropdown.Item>
                               
                                <NavDropdown.Item eventKey="4.4" href="/enviar">Enviar</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Item>
                                <Nav.Link eventKey="1" href="/listado">
                                    Listado
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" href="/administracion">
                                    Administración
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    }
                    {currentUser ? (
                        entrada && <Nav>
                           {!showAdminBoard&& <Nav.Link href="/boletas">Boletas</Nav.Link>}
                            <Nav.Link onClick={logout}>Cerrar sesion</Nav.Link>
                        </Nav>) :
                        (
                            entrada && <Nav>
                                <Nav.Link href="/login">Ingresar</Nav.Link>
                                <Nav.Link href="/registro">Registrate</Nav.Link>
                            </Nav>
                        )
                    }

                </Navbar.Collapse>

            </Navbar>


            <Routes>
                <Route path="/" element={<Home />} />{/*<Entrada entrada={onChangeEntrada} />*/}
                <Route path="/entrada" element={<Entrada entrada={onChangeEntrada} />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login titulo={"Ingresar a Boleta  Única"} />} />
                {currentUser && !showAdminBoard &&  <Route path="/boletas" element={<BoletaUnica />} />}
                <Route path="/registro" element={<Registro />} />
                <Route path="/afterregister" element={<AfterRegister />} />
                <Route path="/nuevopassword" element={<NuevoPassword />} />
                {showAdminBoard && <Route path="/boletaadmin" element={<BoletaUnicaAdmin data={boletas} />} />}
                {currentUser && (showAdminBoard || showModeratorBoard) && <Route path="/tesoreria" element={<Administracion data={dataAdmin} />} />}
                {currentUser && showContadorBoard && <Route path="/contador" element={<BoletaComercio />} />}
                {currentUser && showModeratorBoard && <Route path="/boletas" element={<Moderator />} />}
                {currentUser && showAdminBoard && <Route path="/boletas" element={<Administracion data={dataAdmin} />} />}
                {currentUser && showModeratorBoard &&<Route path="/activar" element={<ActvarDesactivar />} />}
            </Routes>
        </Container>


    );
}

export default App;