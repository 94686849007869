import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, Button, Row, Col } from "react-bootstrap";

export default function Formulario(props) {
    const formSchema = Yup.object().shape({

        UserName: Yup.string()
            .min(4, `Mínimo 5 caracteres`)
            .max(55, `Máximo 55 caracteres`)
            .required("Campo Requerido"),
        Password: Yup.string()
            .required("Campo Requerido")
            .min(5, `Mínimo 5 caracteres`),

    });

    return (
        <>
            <Formik initialValues={
                {
                    UserName: "",
                    Password: "",
                }
            }
                validationSchema={formSchema}
                onSubmit={
                    (values) => { props.valores(values) }} >
                <Form>
                    <FormGroup>
                        <label htmlFor='UserName' > Email: </label>
                        <Field className='form-control'
                            name='UserName'
                            placeholder=''
                            type='text' />
                        <ErrorMessage name='UserName'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='Password' >Nueva contraseña:</label>
                        <Field className='form-control'
                            name='Password'
                            placeholder=''
                            type='password' />
                        <ErrorMessage name='Password'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <Row>
                        <Col lg={12} md={12} >
                            <div className="button-container">
                                <button
                                    class="btnn" type="submit"
                                    disabled={props.loading}>
                                    {props.loading ? ("Te estamos enviando un email ") : ("Cambiar")}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
}