import React, { useEffect, useState } from "react";
import boletaService from "../service/boletas-service";
import { Table, Button } from "react-bootstrap";
import AuthService from "../service/auth.service";
import { TailSpin } from 'react-loader-spinner'

const BoletaUnicaAdmin = (props) => {
   const [boleta, setBoleta] = useState(null);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        setBoleta(props.data)
    }, [props.data])

    const onhandleClick = () => {
        setLoading(true)
        boletaService.boletapdf(boleta.cuim, boleta.nroComprob)
            .then((response) => setLoading(false))

    }
    return (
        <div className="text-center mt-4">
            <h1>Boleta Unica</h1>
            {spinner ?
                (
                    <div className="spinner">
                        <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                ) :
                (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nro. Comprobante</th>
                                <th>Descripcion</th>
                                <th>Cuota</th>
                                <th>Nombre y Apellido</th>
                                <th>Accíon</th>
                            </tr>
                        </thead>
                        {boleta === null ? (null) :
                            (<tbody>
                                <tr>
                                    <td>{boleta.nroComprob}</td>
                                    <td>{boleta.descripcion}</td>
                                    <td>{boleta.cuota}/{boleta.anio}</td>
                                    <td>{boleta.apynom}</td>
                                    <td><Button
                                        variant="outline-success"
                                        onClick={onhandleClick}
                                        disabled={loading}
                                    >{loading ? ("Buscando") : ("Consultar")}
                                    </Button>{' '}</td>
                                </tr>
                            </tbody>)}
                    </Table>
                )}

        </div>
    )
}
export default BoletaUnicaAdmin