import React, { useState,useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, Button, Row, Col, Modal } from "react-bootstrap";
import MaskedInput from 'react-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cuimfoto from "../assest/cuim.jpeg"
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export default function Formulario(props) {
    const [cuim, setCuim] = useState();
    const [isvisible, setVisible] = useState(false)
    const ref=useRef(null);

    const formSchema = Yup.object().shape({
        Email: Yup.string()
            .required("Campo Requerido")
            .email("Correo Electronico Invalido")
            .max(255, `Máximo 255 caracteres`),
        UserName: Yup.string()
            .min(5, `Mínimo 5 caracteres`)
            .max(25, `Máximo 25 caracteres`)
            .required("Campo Requerido"),
        Password: Yup.string()
            .required("Campo Requerido")
            .min(5, `Mínimo 5 caracteres`),
    });

    const onHandleChange = (e) => {
        setCuim(e.target.value)
        console.log(`Cuim ${e.target.value}`)

    }
    useEffect(()=>{
       ref.current.focus()
    },[])
    return (
        <>
            <Formik initialValues={
                {
                    Email: "",
                    UserName: "",
                    Password: "",
                    Cuim: "",
                }
            }
                validationSchema={formSchema}
                onSubmit={
                    (values) => {
                        values.Cuim = cuim;
                        props.valores(values);
                    }
                }>
                <Form >
                    <FormGroup ref={ref}>
                        <label htmlFor='UserName' > Usuario </label>
                        <Field className='form-control'
                            
                            name='UserName'
                            placeholder=''
                            type='text' />
                        <ErrorMessage name='UserName'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup >
                        <label htmlFor='Email' > Correo Electronico: </label>
                        <Field className='form-control'
                            name='Email'
                            placeholder=''
                            type='email' />
                        <ErrorMessage name='Email'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup >
                        <label htmlFor='Password' > Contraseña: </label>
                        <Field className='form-control'
                            name='Password'
                            placeholder=''
                            type='password' />
                        <ErrorMessage name='Password'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup className="icon">
                        <label htmlFor='Cuim' > Cuim: </label>
                        <MaskedInput onChange={onHandleChange}
                            className='form-control'
                            name='Cuim'
                            placeholder=''
                            type='text'
                            mask="11-11111111-1" />
                        <div className="icon__position">
                            <FontAwesomeIcon icon={faQuestionCircle}
                                size="lg"
                                onClick={
                                    () => setVisible(true)
                                }
                            />

                        </div>
                        <ErrorMessage name='Cuim'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <Row>
                        <Col lg={12}
                            md={12} >
                            

                                <div className="button-container" >
                                    <button
                                        class="btnn"
                                        onClick={() => setVisible(false)}
                                        disabled={props.loading} >
                                        {props.loading ? ("Esperar") : ("Ingresar")}
                                    </button>
                                </div>
                            
                        </Col>
                    </Row>
                </Form>

            </Formik>

            <Modal show={isvisible}
                onHide={
                    () => setVisible(false)
                }
                size="lg"

                centered >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter" > Si no sabes tu cuim aquí te indicamos donde encontralo </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <img src={cuimfoto} alt="FOTO CUIM" width="100%"/>
                </Modal.Body>
                <Modal.Footer >
                    
                    <div className="button-container">
                        <button class="btnn" onClick={() => setVisible(false)}>
                            Cerrar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    );
}