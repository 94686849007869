import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import logo from "../assest/login.png";
import FormularioLogin from "../component/Formulario_Login";
import AuthService from "../service/auth.service";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const Login = ({titulo}) => {
    let navigate = useNavigate();
    const[loading,setLoading]=useState(false);

    const onChangeValores = (e) => {
        setLoading(true)
        console.log(`Valores ${e.UserName} pass ${e.Password} cuim ${e.Cuim}`)
        AuthService.login(e.UserName, e.Password)
            .then((response) => {
                setLoading(false);
                navigate("/boletas")
                window.location.reload();
               
            })

            .catch((error) => {
                
                setLoading(false);
                console.log(error.message)
                if(error.message === "Network Error"){error.message="Error de red verifique su conexión o intente mas tarde"}
                const resMessage =
                    
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                
                Swal.fire({
                    text: resMessage,
                    icon: "error",

                })
            })
    }



    return (
        <Container fluid className="App">
            <div className="text-center  titulo">
                <div className="container-login">
                    <Card >
                        <div className="mt-3">
                            <img src={logo} alt="perfil" width="100px" />
                        </div>

                        <Card.Body>
                            <Card.Title><h2>{titulo}</h2></Card.Title>
                            <FormularioLogin valores={onChangeValores} loading={loading}/>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    )
}
export default Login;