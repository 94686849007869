import React, { useState } from "react";
import boletaService from "../service/boletas-service";
import { Col, Form, Row, Button } from "react-bootstrap";
import MaskedInput from 'react-maskedinput';
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";


export default function Administracion(props) {

    const [consulta, setConsulta] = useState();
    const [iscuim, setIscuim] = useState(true);
    const [label, setLabel] = useState("Cuim")
    let navigate = useNavigate();


    const onHandleFiltrar = (e) => {
        setLabel(e.target.value);
        if (e.target.value === "Cuim") {
            setIscuim(true);
        }
        else {
            setIscuim(false);

        }
    }

    const onHandleChangeCuim = (e) => {
        console.log(e.target.value)
        setConsulta(e.target.value)
    }

    const onSubmit = (e) => {

        switch (label) {
            case "Cuim":
                boletaService.getBoleta(consulta)
                    .then((response) => {
                        console.log(response.data.data)
                        props.data(response.data.data)
                        navigate("/boletaadmin")
                    })
                break;
            case "Inmueble":
                boletaService.getBoletaInmueble(parseInt(consulta))
                    .then((response) => {
                        props.data(response.data.data)
                        navigate("/boletaadmin")
                    })
                break;
            case "Comercio":
                boletaService.getBoletaComercio(parseInt(consulta))
                    .then((response) => {
                        props.data(response.data.data)
                        navigate("/boletaadmin")
                    })
                break;
            case "Dominio":
                boletaService.getBoletaDominio(consulta)
                    .then((response) => {
                        props.data(response.data.data)
                        navigate("/boletaadmin")
                    })
                break;
            default:

                break;
        }
        console.log("Consulta :" + consulta)
    }


    return (
        <>
            <div className="text-center mt-5 titulo">
                <h1>Administración</h1>
            </div>
            <Row>
                <Col>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="filtrar">Filtrar por </Form.Label>
                        <Form.Select id="filtrar" onChange={onHandleFiltrar}>
                            <option value="Cuim" selected>CUIM</option>
                            <option value="Inmueble">INMUEBLE</option>
                            <option value="Comercio">COMERCIO</option>
                            <option value="Dominio">DOMINIO</option>

                        </Form.Select>
                    </Form.Group>

                </Col>
                <Col>

                    <Form.Group className="mb-3" hidden={!iscuim}>
                        <Form.Label htmlFor='Cuim'>{label}: </Form.Label>
                        <MaskedInput
                            onChange={onHandleChangeCuim}
                            className='form-control'
                            name='Cuim'
                            placeholder=''
                            type='text'
                            mask="11-11111111-1"
                        />

                    </Form.Group >
                    <Form.Group className="mb-3" hidden={iscuim}>
                        <Form.Label>{label}: </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={onHandleChangeCuim}
                            placeholder=''

                        />

                    </Form.Group>
                </Col>
                <Button
                    type="text"
                    onClick={onSubmit}
                >
                    Buscar
                </Button>
            </Row>
        </>
    )
}