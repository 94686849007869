import React, {  useState } from "react";
import { Card, Container } from "react-bootstrap";
import logo from "../assest/login.png";
//import FormularioLogin from "../component/Formulario_Login";
import FormularioLogin from "../component/Formulario_registros";
import AuthService from "../service/auth.service";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import boletaService from "../service/boletas-service";

const Registro = () => {

    let navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);

    const onChangeValores = (e) => {
        
        setLoading(true);
        AuthService.register(e.UserName, e.Email, e.Password, e.Cuim)
            .then((response) => {
                boletaService.enviarConfirmacion(e.Email, e.UserName)
                    .then(() => {
                        navigate("/afterregister")
                        window.location.reload();
                        setLoading(false);
                    })

            })
            .catch((error) => {
                setLoading(false);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                //console.log(resMessage);
                Swal.fire({
                    text: resMessage,
                    icon: "error",

                })
            })
    }



    return (
        <Container className="App">
            <div className="text-center  titulo">
                
                <div className="container-login">
                    <Card >
                        <div className="mt-3">
                            <img src={logo} alt="perfil" width="100px" />
                        </div>

                        <Card.Body>
                            <Card.Title><h2>Registrate a Boleta Única </h2></Card.Title>
                            <FormularioLogin valores={onChangeValores} loading={loading} />
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    )
}
export default Registro;