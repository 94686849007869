import React, { useEffect, useState } from "react"
import boletaService from "../service/boletas-service";
import { Table, Modal, Button, Form } from "react-bootstrap";


const ActvarDesactivar = () => {
    const [usuario, setUsuario] = useState([])
    const [roless, setRoless] = useState([])
    const [fila, setFila] = useState()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (dato) => {
        console.log(dato)
        setFila(dato)
        setShow(true)


    };
    const handleSelect = (e) => {
        let id = e.target.value
        let role = roless.find(r => r.id === Number(id))
        const newFila = [fila]
        newFila[0].roles[0].name = role.name
        newFila[0].roles[0].id = role.id
        console.log(newFila[0].roles[0].name)
        console.log(newFila[0].roles[0].id)
        setFila(newFila[0])


    }
    const onDSubmit = () => {
        console.log(fila)
        boletaService.modificarRole(fila)
            .then((resp) => {
                console.log(resp)
                if (resp.data.success) setShow(false)

            })
    }
    useEffect(() => {
        boletaService.getUserAll()
            .then((resp) => {

                if (resp.data.success) {
                    setUsuario(resp.data.data)
                }
            })
    }, [])

    useEffect(() => {
        boletaService.getRolesAll()
            .then((resp) => {
                console.log(`Roles ${JSON.stringify(resp.data.data)}`)
                if (resp.data.success) {
                    setRoless(resp.data.data)
                }

            })

    }, [])
    
    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Cuim</th>
                        <th>Usuario</th>
                        <th>Email</th>
                        <th>Rol</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usuario.map((data, i) => {

                            return (
                                <tr key={i}>
                                    <td>{data?.cuim}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.email}</td>
                                    <td>{data?.roles[0].name}</td>
                                    <td><div className="button-container">
                                        <button onClick={() => handleShow(data)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </Table>

            {/*Modal para editar rol*/}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Roles</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3" controlId="cuim">
                            <Form.Label>Cuim</Form.Label>
                            <Form.Control type="text" disabled value={fila?.cuim} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control type="text" disabled value={fila?.name} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" disabled value={fila?.email} />
                        </Form.Group>
                        <Form.Label>Selecciona el rol</Form.Label>
                        <Form.Select defaultValue={fila?.roles[0].id} size="sm" onChange={handleSelect}>
                            {roless.map((data, i) => {
                                return (
                                    <option value={data.id}>{data.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onDSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ActvarDesactivar